export function IconCardsOutline(props) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M11.19 2.25c-.26 0-.52.06-.77.15L3.06 5.45a1.994 1.994 0 00-1.09 2.6L6.93 20a2 2 0 001.81 1.25c.26 0 .53-.03.79-.15l7.37-3.05a2.02 2.02 0 001.23-1.8c.01-.25-.04-.54-.13-.8L13 3.5a1.954 1.954 0 00-1.81-1.25m3.48 0l3.45 8.35V4.25a2 2 0 00-2-2m4.01 1.54v9.03l2.43-5.86a1.99 1.99 0 00-1.09-2.6m-10.28-.14l4.98 12.02-7.39 3.06L3.8 7.29" />
    </svg>
  );
}
