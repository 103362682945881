import { useState, useEffect } from "react";
import { trackEvent, trackSelect } from "../../lib/utils/analytics";
import { IconQuestionCircle } from "../icons/IconQuestionCircle";
import { BaseDialog } from "./BaseDialog";
import usePersistentStore from "../../lib/store/PersistentStore";
import { shallow } from "zustand/shallow";
import useGameStore from "../../lib/store/GameStore";

function HowToPlayButton({ modalTitle }) {
  const [saveInstructionsDismissed, history, guessesLeft] = usePersistentStore(
    (state) => [
      state.saveInstructionsDismissed,
      state.history,
      state.guessesLeft,
    ],
    shallow
  );

  const [setIsOpen] = useGameStore((state) => [state.setIsHelpOpen]);

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const show = Object.keys(history).length > 1 || guessesLeft == 0;

  function closeModal() {
    saveInstructionsDismissed(true);
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    trackEvent("how_to_play_button");
    trackSelect({
      contentType: "button",
      itemId: "how_to_play",
    });
  }

  return (
    <>
      {isClient && !show ? (
        <button
          aria-label={modalTitle}
          onClick={openModal}
          className="-mx-1 -my-0.5 inline-flex cursor-pointer items-center rounded-full px-3 py-2 text-xs font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-800 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 sm:px-4 md:text-sm"
        >
          How to Play
          {/* <IconQuestionCircle className="ml-2 h-5 w-5 md:ml-3 md:h-6 md:w-6" /> */}
        </button>
      ) : (
        <button
          aria-label={modalTitle}
          onClick={openModal}
          className="-my-2 flex w-10 items-center justify-center py-2 text-xs font-bold uppercase text-black md:w-auto md:px-2 md:text-sm"
        >
          <IconQuestionCircle className="h-5 w-5 md:h-7 md:w-7" />
        </button>
      )}
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 px-2 pt-2 text-md sm:px-4">
      <ul className="list-none space-y-5">
        <HelpListItem afterText="You have nine guesses to fill out the grid." />
        <HelpListItem afterText="Select a player for each cell that matches the criteria for that cell's row and column." />
        <HelpListItem
          afterText={
            <>
              For teams, the player must have played{" "}
              <strong>at least one game</strong> for that team at AFL/VFL level,
              or currently on their list.
            </>
          }
        />

        {/* <HelpListItem
          bgColor="bg-green-700"
          textColor="text-white"
          highlightText="TIPS"
          afterText={
            <>
              &nbsp;For help, <strong>click the row or column header</strong>{" "}
              for more information about the criteria.
            </>
          }
        /> */}
        <HelpListItem
          afterText={
            <>
              For awards and statuses, the player <strong>does not</strong> have
              to have earned the accomplishment while on that team.
            </>
          }
        />
        <HelpListItem
          afterText={
            <>
              Click on a team or category name to reveal more about it's
              criteria.
            </>
          }
        />
        {/* <HelpListItem
          afterText={
            <>
              <strong>Rarity scores</strong> are based on how unique your
              selections are vs. other Gridley players. Select unique players to
              keep your rarity score low!
            </>
          }
        /> */}
        <HelpListItem afterText="A player cannot be used more than once." />

        <HelpListItem afterText="Come back each day for a new grid!" />
      </ul>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default HowToPlayButton;
