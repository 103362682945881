import { handleTweet } from "../../lib/utils/share";
import { trackShare } from "../../lib/utils/analytics";
import { gridLevelNumber } from "../../lib/utils/gridLevels";

function TweetButton() {
  const tweetCopy = handleTweet();
  const href =
    "https://twitter.com/intent/tweet?text=" + encodeURIComponent(tweetCopy);

  function trackClick() {
    trackShare({ method: "Twitter", itemId: gridLevelNumber });
  }

  return (
    <a
      href={href}
      target="_blank"
      onClick={trackClick}
      className="mb-2 mr-2 inline-flex items-center rounded-lg bg-[#0f1419] px-6 py-2.5 text-center text-sm font-medium text-white hover:bg-gray-800 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-600"
    >
      <svg
        aria-hidden="true"
        className="-ml-1 mr-2 h-5 w-6"
        width="1200"
        height="1227"
        viewBox="0 0 1200 1227"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
          fill="white"
        />
      </svg>

      <span className="hidden md:inline-block">Share to X</span>
      <span className="md:hidden">Post</span>
    </a>
  );
}

export default TweetButton;
