import React from "react";
import { useField, useFormikContext } from "formik";

export const InputField = ({ label, hint, hintId, ...props }) => {
  const [field, meta] = useField(props);
  const { submitCount } = useFormikContext();
  const showError =
    meta.error && ((meta.value && meta.touched) || submitCount > 0);

  const styleError =
    "focus-visible:outline-red-400 text-red-900 bg-red-50 border-red-500 placeholder-red-700 dark:border-red-400 dark:text-red-900 focus:z-10 focus:ring-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500";

  return (
    <div className="form-control w-full">
      <label
        htmlFor={props.id || props.name}
        className="mb-1 block pl-px text-xs font-medium text-gray-900 dark:text-white"
      >
        <span className="label-text">{label}</span>
      </label>
      <input
        className={`block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-gray-900 focus:z-10 focus-visible:outline-2 focus-visible:outline-blue-400 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-red-500 dark:focus:ring-red-500 sm:text-sm ${
          showError ? styleError : ""
        }`}
        {...field}
        {...props}
      />
      {showError ? (
        <p className="mb-4 mt-2 text-sm font-light text-red-600 dark:text-red-400">
          {meta.error}
        </p>
      ) : hint ? (
        <label id={hintId} className="label pt-1">
          <span className="label-text-alt">{hint}</span>
        </label>
      ) : null}
    </div>
  );
};
