import { createWithEqualityFn } from "zustand/traditional";
import { gridLevelDateKey } from "../utils/gridLevels";
import { fetchUrl } from "../utils/fetchUrl";
import usePersistentStore from "./PersistentStore";
import { postSubmission } from "../utils/submit";

const useGameStore = createWithEqualityFn(
  (set, get) => ({
    grid: null,
    setGrid: (grid) => {
      set({ grid: grid });
    },

    levelNumber: null,
    setLevelNumber: (number) => {
      set({ levelNumber: number });
    },

    initError: false,
    setInitError: (value) => set({ initError: value }),

    globalStats: null,
    setGlobalStats: (statsJson) => {
      set({ globalStats: statsJson });
    },

    leaderboard: null,

    user: null,
    isUserLoading: true,
    setUser: (user) => set({ user: user }),

    currentUserId: null,
    setCurrentUserId: (id) => set({ currentUserId: id }),

    analyticsGameName: "",
    setAnalyticsGameName: (name) => set({ analyticsGameName: name }),

    shareGameName: "",
    setShareGameName: (name) => set({ shareGameName: name }),

    shareGameUrl: "",
    setShareGameUrl: (url) => set({ shareGameUrl: url }),

    isSilhouetteOpen: false,
    setIsSilhouetteOpen: (isOpen) =>
      set((state) => ({ isSilhouetteOpen: isOpen })),

    isAnswerOpen: false,
    setIsAnswerOpen: (isOpen) => set((state) => ({ isAnswerOpen: isOpen })),

    selectedTab: "my",
    setSelectedTab: (tab) => set((state) => ({ selectedTab: tab })),

    leaderboardTab: "friends",
    setLeaderboardTab: (tab) => set((state) => ({ leaderboardTab: tab })),

    noSpoilers: false,
    toggleNoSpoilers: () =>
      set((prevState) => ({
        noSpoilers: !prevState.noSpoilers,
      })),

    undoModeActive: false,
    setUndoModeActive: (isActive) =>
      set((state) => ({ undoModeActive: isActive })),

    isResultOpen: false,
    setIsResultOpen: (isOpen) => set((state) => ({ isResultOpen: isOpen })),

    isRegisterOpen: false,
    setIsRegisterOpen: (isOpen) => set((state) => ({ isRegisterOpen: isOpen })),

    isPromoOpen: true,
    setIsPromoOpen: (isOpen) => set((state) => ({ isPromoOpen: isOpen })),

    isCaptureOpen: false,
    setIsCaptureOpen: (isOpen) => set((state) => ({ isCaptureOpen: isOpen })),

    isCaptureComplete: false,
    setIsCaptureComplete: (isComplete) =>
      set((state) => ({ isCaptureComplete: isComplete })),

    isHelpOpen: false,
    setIsHelpOpen: (isOpen) => set((state) => ({ isHelpOpen: isOpen })),

    isPickHistoryOpen: false,
    setIsPickHistoryOpen: (isOpen) =>
      set((state) => ({ isPickHistoryOpen: isOpen })),

    isGameHistoryOpen: false,
    setIsGameHistoryOpen: (isOpen) =>
      set((state) => ({ isGameHistoryOpen: isOpen })),

    fetchGame: async () => {
      try {
        const gridJson = await fetchUrl(
          `${process.env.NEXT_PUBLIC_API_BASE_URL}/data/grids/${gridLevelDateKey}.json`
        );

        set({
          globalStats: gridJson,
          levelNumber: gridJson.level,
          grid: {
            levelNumber: gridJson.level,
            hItems: gridJson.hItems,
            vItems: gridJson.vItems,
            correctAnswersPlayerMap: gridJson.correctAnswersPlayerMap,
          },
        });
      } catch (error) {
        console.log(error.message);
        set({ initError: true });
      }
    },

    fetchUser: async () => {
      try {
        const userJson = await fetchUrl(
          `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/user/score/${gridLevelDateKey}`
        );

        console.log("User is", userJson.user);
        set({ user: userJson.user, isUserLoading: false });

        const user = get().user;
        if (user) {
          if (userJson.attempt) {
            console.log("Found remote attempt, store and use locally");
            await usePersistentStore.getState().mergeRemoteAttempt(userJson);
          } else {
            console.log("Did not find a remote attempt");
            console.log("Check if there is a local attempt that needs syncing");

            const userHistory = usePersistentStore.getState().userHistory;
            const userAttempt =
              userHistory?.[user.id]?.[gridLevelDateKey] ?? null;

            if (userAttempt && userAttempt.guessesLeft == 0) {
              console.log("Found completed attempt -> sync");

              postSubmission({
                gridKey: gridLevelDateKey,
                browserId: usePersistentStore.getState().browserId,
                correctGuesses: userAttempt.correctGuesses,
                incorrectGuesses: userAttempt.incorrectGuesses || [
                  [[], [], []],
                  [[], [], []],
                  [[], [], []],
                ],
                gaveUp: userAttempt.gaveUp,
                undoUsed: userAttempt.undoUsed,
              });
            } else if (userAttempt) {
              console.log("Found partial attempt");
            } else {
              console.log("No user completed guesses to sync");
            }
          }
        }

        usePersistentStore.getState().setBoard(get().user);
      } catch (error) {
        console.log(error.message);
        set({ initError: true });
      }
    },

    fetchLeaders: async () => {
      try {
        const leadersJson = await fetchUrl(
          `${process.env.NEXT_PUBLIC_API_BASE_URL}/api/leaderboard/${gridLevelDateKey}`
        );

        set({
          leaderboard: leadersJson,
        });

        console.log(leadersJson);
      } catch (error) {
        console.log(error.message);
      }
    },

    setupLevel: () => {
      get().fetchUser();
      get().fetchGame();
      //      get().fetchLeaders();
      get().setAnalyticsGameName("Gridley");
      get().setShareGameName("Gridley");
      get().setShareGameUrl("https://gridleygame.com/");
    },

    clearState: () => {
      console.log("clearing state");
      set((state) => ({
        target: null,
        players: [],
        isSilhouetteOpen: false,
        isAnswerOpen: false,
        isResultOpen: false,
      }));
    },
  }),
  Object.is
);

export default useGameStore;
