import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "./BaseDialog";

function HowToPlayDialog({ modalTitle = "How to Play Gridley" }) {
  const [isOpen, setIsOpen] = useGameStore((state) => [
    state.isHelpOpen,
    state.setIsHelpOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={modalTitle}
        modalBody={<HowToPlayBody />}
      />
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 px-2 pt-2 text-md sm:px-4">
      <ul className="list-none space-y-5">
        <HelpListItem afterText="You have nine guesses to fill out the grid." />
        <HelpListItem afterText="Select a player for each cell that matches the criteria for that cell's row and column." />
        <HelpListItem
          afterText={
            <>
              For teams, the player must have played{" "}
              <strong>at least one game</strong> for that team at AFL/VFL level,
              or currently on their list.
            </>
          }
        />

        {/* <HelpListItem
          bgColor="bg-green-700"
          textColor="text-white"
          highlightText="TIPS"
          afterText={
            <>
              &nbsp;For help, <strong>click the row or column header</strong>{" "}
              for more information about the criteria.
            </>
          }
        /> */}
        <HelpListItem
          afterText={
            <>
              For awards and statuses, the player <strong>does not</strong> have
              to have earned the accomplishment while on that team.
            </>
          }
        />
        <HelpListItem
          afterText={
            <>
              Click on a team or category name to reveal more about it's
              criteria.
            </>
          }
        />
        {/* <HelpListItem
          afterText={
            <>
              <strong>Rarity scores</strong> are based on how unique your
              selections are vs. other Gridley players. Select unique players to
              keep your rarity score low!
            </>
          }
        /> */}
        <HelpListItem afterText="A player cannot be used more than once." />

        <HelpListItem afterText="Come back each day for a new grid!" />
      </ul>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default HowToPlayDialog;
