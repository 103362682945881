import React from "react";
import { useField, useFormikContext } from "formik";

export const CheckboxField = ({ id, label, hint, hintId, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  const { submitCount } = useFormikContext();
  const showError =
    meta.error && ((meta.value && meta.touched) || submitCount > 0);

  return (
    <>
      <div className="flex items-start">
        <div className="flex h-5 items-center">
          <input
            id={id}
            aria-describedby="remember"
            type="checkbox"
            className={`focus:ring-3 h-4 w-4 rounded border border-gray-300 bg-gray-50 focus:ring-red-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-red-600`}
            {...field}
            {...props}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={id} className="label cursor-pointer">
            <span className="text-sm text-gray-700 dark:text-gray-300">
              {label}
            </span>
          </label>
          {showError ? (
            <p className="mt-2 text-sm font-light text-red-600 dark:text-red-400">
              {meta.error}
            </p>
          ) : hint ? (
            <label id={hintId} className="label pt-1">
              <span className="label-text-alt">{hint}</span>
            </label>
          ) : null}
        </div>
      </div>
    </>
  );
};
