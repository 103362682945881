import { useState, useEffect, useMemo } from "react";
import { BaseDialog } from "./BaseDialog";
import { trackEvent, trackSelect } from "../../lib/utils/analytics";
import { IconBarChart } from "../icons/IconBarChart";
import CountUp from "react-countup";
import {
  calcAvgRarity,
  calcAvgSquares,
  calcPctForSquare,
} from "../../lib/utils/calcRarity";
import teamMap from "../../lib/utils/teamMap";
import usePersistentStore from "../../lib/store/PersistentStore";
import useGameStore from "../../lib/store/GameStore";
import { shallow } from "zustand/shallow";
import { Divider } from "./Divider";

function StatsDialog(params) {
  const [localHistory, userHistory, guessesLeft] = usePersistentStore(
    (state) => [state.history, state.userHistory, state.guessesLeft],
    shallow
  );

  const [user, isUserLoading] = useGameStore(
    (state) => [state.user, state.isUserLoading],
    shallow
  );

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const currentUserHistory = userHistory?.[user?.id] ?? {};
  const mergedHistory = Object.assign({}, localHistory, currentUserHistory);
  const stats = useMemo(() => getStats(mergedHistory), [mergedHistory]);

  const show = Object.keys(mergedHistory).length > 1 || guessesLeft == 0;

  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    trackEvent("stats_button");
    trackSelect({
      contentType: "button",
      itemId: "my_stats",
    });
  }

  return (
    <>
      {isClient && show && (
        <button
          onClick={openModal}
          className="-my-1 flex w-10 items-center justify-center py-2 font-bold uppercase text-black md:w-auto md:px-2"
        >
          <IconBarChart className="h-5 w-5 md:h-7 md:w-7" />
        </button>
      )}

      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle="My Statistics"
        modalBody={<ModalBody stats={stats} history={mergedHistory} />}
      />
    </>
  );
}

function getStats(history) {
  const levelNumbersPlayed = Object.keys(history);
  const levelNumbersPlayedReversed = Object.keys(history).reverse();

  const levelResults = Object.values(history);
  const resultsCountsInCompletedGames = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(
    (elem) => ({
      result: elem,
      count: levelResults.reduce(
        (count, guess) =>
          guess.score === elem && guess.guessesLeft == 0 ? count + 1 : count,
        0
      ),
    })
  );

  const totalCompletedGames = levelResults.reduce(
    (count, guess) => (guess.guessesLeft == 0 ? count + 1 : count),
    0
  );
  const totalScoreInCompletedGames = levelResults.reduce(
    (count, guess) => (guess.guessesLeft == 0 ? count + guess.score : count),
    0
  );

  const calcCurrentStreak = () => {
    const startGame = history[levelNumbersPlayedReversed[0]].level;

    let streak = 0;
    levelNumbersPlayedReversed.forEach((dateKey, i) => {
      const isStreakAlive = streak === i;
      const game = history[dateKey];

      if (isStreakAlive && game.level === startGame - i && game.score === 9) {
        streak++;
      }
    });
    return streak;
  };

  const calcBestStreak = () => {
    const firstGameResult = levelResults[0];
    let currStreak = firstGameResult.score === 9 ? 1 : 0;
    let bestStreak = currStreak;

    for (let i = 1; i < levelNumbersPlayed.length; i++) {
      const currentDayDateKey = levelNumbersPlayed[i];
      const currentDayGame = history[currentDayDateKey];
      const prevDayGame = history[levelNumbersPlayed[i - 1]];
      if (
        currentDayGame.level === prevDayGame.level + 1 &&
        currentDayGame.score === 9
      ) {
        currStreak++;
        bestStreak = Math.max(currStreak, bestStreak);
      } else if (currentDayGame.score === 9) {
        currStreak = 1;
      } else {
        currStreak = 0;
      }
    }

    return bestStreak;
  };

  if (totalCompletedGames === 0) {
    return {
      firstPlayed: null,
      played: levelResults.length,
      streak: 0,
      bestStreak: 0,
      completed: totalCompletedGames,
      totalScoreInCompletedGames: totalScoreInCompletedGames,
      avgScore: 0,
      histogram: resultsCountsInCompletedGames,
    };
  }

  const stats = {
    firstPlayed: levelNumbersPlayed[0],
    played: levelResults.length,
    streak: calcCurrentStreak(),
    bestStreak: calcBestStreak(),
    completed: totalCompletedGames,
    totalScoreInCompletedGames: totalScoreInCompletedGames,
    avgScore: (totalScoreInCompletedGames / totalCompletedGames).toFixed(1),
    histogram: resultsCountsInCompletedGames,
  };

  return stats;
}

function ModalBody({ stats }) {
  const {
    firstPlayed,
    completed,
    avgScore,
    played,
    won,
    streak,
    bestStreak,
    histogram,
  } = stats;

  const avg = completed === 0 ? 0 : avgScore;
  const mode = Math.max(...histogram.map((o) => o.count));

  return (
    <div className="-mx-2 px-1 text-center ">
      {/* <div
        className="-mb-2 mt-6 inline-flex items-center justify-between rounded-full bg-gray-100 px-1 py-1 pr-4 text-sm text-gray-700  dark:bg-gray-800 dark:text-white"
        role="alert"
        href="/icons/"
      >
        <span className="mr-3 rounded-full bg-green-700 px-3 py-1 text-xs text-white dark:bg-blue-600">
          Fixed
        </span>
        <span className="mr-2 text-xs font-medium">
          My Stats update again after each game
        </span>
      </div> */}
      <div className="my-4 flex flex-row justify-evenly md:my-10">
        <StatBox title="Played" value={completed} />
        <StatBox title={"Avg Squares"} value={avgScore} />
        <StatBox title="Perfect Streak" value={streak} />
        <StatBox title="Best Streak" value={bestStreak} />
      </div>
      <div className="mb-6 px-4">
        {/* <p className="font-bold md:text-lg">More stats coming soon...</p> */}
        {/* <h3 className="mb-2 uppercase md:text-xl">Guess Distribution</h3>
          {histogram.map(({ result, count }) => (
            <ChartBar
              key={result}
              label={result}
              value={count}
              total={played}
              mode={mode}
            />
          ))} */}
      </div>
      <Divider />
      <DailySummary />
    </div>
  );
}

export function DailySummary() {
  return (
    <>
      <DailyStats />
      <Divider />
      <div className="mt-8">
        <div className="mb-4 flex justify-center text-xl font-semibold uppercase  dark:text-gray-200">
          Accuracy
        </div>
        <AccuracyGrid />
      </div>
      <Divider />
      <div className="mb-12 mt-8 px-4">
        <div className="mb-4 flex justify-center text-xl font-semibold uppercase text-gray-900 dark:text-gray-200">
          Player Scores
        </div>
        <ScoresChart />
        <div className="mt-1.5 flex justify-center text-sm font-semibold uppercase text-gray-700 dark:text-gray-400">
          {" "}
          Correct Squares
        </div>
      </div>
    </>
  );
}

export function DailyStats() {
  const [levelNumber, globalStats] = useGameStore((state) => [
    state.levelNumber,
    state.globalStats,
  ]);

  const avgSquares = calcAvgSquares(globalStats).toFixed(1);
  const avgRarity = calcAvgRarity(globalStats);

  return (
    <>
      <div className="mt-8">
        <h2 className="text-center text-xl font-semibold uppercase dark:text-white sm:text-2xl">
          Today's Stats
        </h2>
      </div>
      <div className="mt-6">
        <div className="flex justify-center">
          <div className="w-1/2">
            <div className="flex justify-center text-center text-sm font-semibold uppercase text-gray-700 dark:text-gray-200 sm:text-base">
              {" "}
              Gridley Players{" "}
            </div>
            <div className="mt-2 text-center text-4xl font-bold tracking-tight dark:text-white">
              <CountUp
                end={globalStats.started}
                useEasing={false}
                duration={0.4}
              />
            </div>
          </div>
          {/* <div className="w-1/2">
        <div className="flex justify-center text-center text-sm font-semibold uppercase text-gray-700 dark:text-gray-200 sm:text-base">
          {" "}
          Avg. Squares{" "}
        </div>
        <div className="mt-2 text-center text-4xl font-bold dark:text-white">
          <CountUp
            end={avgSquares}
            decimals={1}
            useEasing={false}
            duration={0.4}
            // delay={0.2}
          />
        </div>
      </div> */}
          <div className="w-1/2">
            <div className="flex justify-center text-center text-sm font-semibold uppercase text-gray-700 dark:text-gray-200 sm:text-base">
              {" "}
              Avg. Rarity{" "}
            </div>
            <div className="mt-2 text-center text-4xl font-bold dark:text-white">
              <CountUp end={avgRarity} useEasing={false} duration={0.4} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function ScoresChart() {
  const [globalStats] = useGameStore((state) => [state.globalStats]);

  const completed = Math.max(globalStats.completed, 1);
  const scoreMap = globalStats.scoreMap;
  const mode = Math.max(...scoreMap);

  return (
    <>
      <div className="mt-1 flex h-80">
        <ChartLine value={scoreMap[0]} completed={completed} mode={mode} />
        <ChartLine value={scoreMap[1]} completed={completed} mode={mode} />
        <ChartLine value={scoreMap[2]} completed={completed} mode={mode} />
        <ChartLine value={scoreMap[3]} completed={completed} mode={mode} />
        <ChartLine value={scoreMap[4]} completed={completed} mode={mode} />
        <ChartLine value={scoreMap[5]} completed={completed} mode={mode} />
        <ChartLine value={scoreMap[6]} completed={completed} mode={mode} />
        <ChartLine value={scoreMap[7]} completed={completed} mode={mode} />
        <ChartLine value={scoreMap[8]} completed={completed} mode={mode} />
        <ChartLine value={scoreMap[9]} completed={completed} mode={mode} />
      </div>
      <div className="border-gray-150 mt-3 flex border-t pt-2 dark:border-gray-700">
        <ChartAxisLabel value={0} />
        <ChartAxisLabel value={1} />
        <ChartAxisLabel value={2} />
        <ChartAxisLabel value={3} />
        <ChartAxisLabel value={4} />
        <ChartAxisLabel value={5} />
        <ChartAxisLabel value={6} />
        <ChartAxisLabel value={7} />
        <ChartAxisLabel value={8} />
        <ChartAxisLabel value={9} />
      </div>
    </>
  );
}

function ChartAxisLabel({ value }) {
  return (
    <div className="flex w-[10%] justify-center font-medium text-gray-700 dark:text-gray-200">
      {value}
    </div>
  );
}

function ChartLine({ value, completed, mode }) {
  const pct = (value / completed) * 100;
  const label = pct.toFixed(pct < 10 ? 1 : 0);
  const heightPct = ((value / mode) * 100).toFixed(2);

  return (
    <div className="flex h-full w-[10%] flex-col items-center justify-end">
      <span className="mb-1 text-xxs font-medium text-gray-800 dark:text-gray-300 sm:text-xs">
        {label}%
      </span>
      <div
        className="w-5 transform rounded bg-green-500 transition-all duration-500 ease-out"
        style={{ height: `${heightPct}%` }}
      ></div>
    </div>
  );
}

export function AccuracyGrid() {
  const [grid, globalStats] = useGameStore((state) => [
    state.grid,
    state.globalStats,
  ]);

  return (
    <div className="-ml-2 -mr-4 flex justify-center">
      <div>
        <div className="flex">
          <div className="-ml-4 w-24 sm:-ml-10 sm:w-32"></div>
          <ColHeader item={grid.hItems[0]} />
          <ColHeader item={grid.hItems[1]} />
          <ColHeader item={grid.hItems[2]} />
        </div>
        <div className="flex items-center">
          <div>
            <RowHeader item={grid.vItems[0]} />
            <RowHeader item={grid.vItems[1]} />
            <RowHeader item={grid.vItems[2]} />
          </div>
          <div className="grid grid-cols-3 overflow-hidden rounded-xl border bg-white dark:border-gray-950 dark:bg-gray-800">
            <div className="flex h-20 w-20 items-center justify-center overflow-hidden border-b border-r font-semibold text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl">
              {Math.round(calcPctForSquare(globalStats, 0, 0) * 100)}%
            </div>
            <div className="flex h-20 w-20 items-center justify-center overflow-hidden border-b border-r font-semibold text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl">
              {Math.round(calcPctForSquare(globalStats, 0, 1) * 100)}%
            </div>
            <div className="flex h-20 w-20 items-center justify-center overflow-hidden border-b font-semibold text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl">
              {Math.round(calcPctForSquare(globalStats, 0, 2) * 100)}%
            </div>
            <div className="flex h-20 w-20 items-center justify-center overflow-hidden border-b border-r font-semibold text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl">
              {Math.round(calcPctForSquare(globalStats, 1, 0) * 100)}%
            </div>
            <div className="flex h-20 w-20 items-center justify-center overflow-hidden border-b border-r font-semibold text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl">
              {Math.round(calcPctForSquare(globalStats, 1, 1) * 100)}%
            </div>
            <div className="flex h-20 w-20 items-center justify-center overflow-hidden border-b font-semibold text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl">
              {Math.round(calcPctForSquare(globalStats, 1, 2) * 100)}%
            </div>
            <div className="flex h-20 w-20 items-center justify-center overflow-hidden border-r font-semibold text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl">
              {Math.round(calcPctForSquare(globalStats, 2, 0) * 100)}%
            </div>
            <div className="flex h-20 w-20 items-center justify-center overflow-hidden border-r font-semibold text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl">
              {Math.round(calcPctForSquare(globalStats, 2, 1) * 100)}%
            </div>
            <div className="flex h-20 w-20 items-center justify-center overflow-hidden font-semibold text-gray-700 transition-colors duration-75 dark:border-gray-950 sm:h-28 sm:w-28 sm:text-2xl">
              {Math.round(calcPctForSquare(globalStats, 2, 2) * 100)}%
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ColHeader({ item }) {
  const type = item.id.length > 2 ? "award" : "team";

  return (
    <div className="flex h-16 w-20 items-center justify-center text-xs sm:h-20 sm:w-28 ">
      {type == "award" ? (
        <AwardHeader item={item} pos="col" />
      ) : (
        <TeamLogo item={item} />
      )}
    </div>
  );
}

function RowHeader({ item }) {
  const type = item.id.length > 2 ? "award" : "team";

  return (
    <div className="-ml-4 flex h-20 w-24 items-center justify-center text-xs  sm:-ml-10 sm:h-28 sm:w-32">
      {type == "award" ? (
        <AwardHeader item={item} pos="row" />
      ) : (
        <TeamLogo item={item} />
      )}
    </div>
  );
}

export function TeamLogo({ item }) {
  const team = teamMap[item.id];

  return (
    <div>
      <img
        className="h-11 w-11 rounded-full sm:h-14 sm:w-14"
        src={`/img/logos/512/${team.logoId}.png`}
        alt={`${team.full_name} colours`}
      ></img>
    </div>
  );
}

export function AwardHeader({ item }) {
  return (
    <div className="font-display flex h-20 flex-col items-center justify-center text-center text-xs font-bold leading-tight text-gray-800 dark:text-white sm:h-28 sm:text-sm  ">
      {item.imgUrl ? (
        <img
          className="mb-1 mt-1 h-16 w-16 sm:h-24 sm:w-24"
          src={item.imgUrl}
          alt={item.title}
        ></img>
      ) : (
        <div className="leading-tight">{item.title}</div>
      )}
      <div className="text-xxs uppercase text-gray-600 dark:text-gray-400 sm:text-xs">
        {item.subtitle}
      </div>
    </div>
  );
}

function StatBox({ title, value }) {
  return (
    <div className="mx-1 flex w-1/3 flex-col rounded-md bg-slate-200 py-2 md:pt-3">
      <div className="mb-1 flex h-8 flex-col justify-center px-4 text-xs font-normal leading-tight opacity-80 md:text-sm">
        {title}
      </div>
      <div className="text-2.5xl md:text-3xl">{value}</div>
    </div>
  );
}

function ChartBar({ label, value, total, mode }) {
  const highlight = value === mode;
  const pct = total == 0 ? 0 : Math.round((value / total) * 100);
  const widthPct = Math.round((value / mode) * 100) * 0.75;
  const barColor = highlight ? "bg-slate-300" : "bg-slate-300";
  return (
    <div className="flex flex-row items-center justify-start">
      <div className="inline-block w-5 text-sm">{label}</div>
      <div className={`inline-block h-5 w-2 ${barColor} my-1`}></div>
      <div
        className={`inline-block h-5 ${barColor} my-1 -ml-0.5 rounded-r-md`}
        style={{ width: `${widthPct}%` }}
      ></div>
      <div className="ml-1.5 inline-block w-8 text-left text-xs opacity-70">
        {pct}%
      </div>
    </div>
  );
}
function ModalCloseButton({ clickCallback }) {
  return (
    <button
      type="button"
      className="absolute right-1.5 -m-1.5 mr-2 rounded-full p-1.5 transition-colors duration-75 hover:bg-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#59d185] dark:text-gray-300 dark:hover:bg-gray-700"
      onClick={clickCallback}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        aria-hidden="true"
        className="h-6 w-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
  );
}

export default StatsDialog;
