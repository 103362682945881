import { useState } from "react";
import useGameStore from "../../lib/store/GameStore";
import { BaseDialog } from "./BaseDialog";

function PromoDialog({ modalTitle = "Kangaroos Gridley Day" }) {
  const [isOpen, setIsOpen] = useGameStore((state) => [
    state.isPromoOpen,
    state.setIsPromoOpen,
  ]);

  const [setIsCaptureOpen, setIsPromoOpen] = useGameStore((state) => [
    state.setIsCaptureOpen,
    state.setIsPromoOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <BaseDialog
        isOpen={isOpen}
        closeModal={closeModal}
        modalTitle={modalTitle}
        modalBody={<HowToPlayBody />}
        size="md"
      />
    </>
  );
}

function HowToPlayBody() {
  return (
    <div className="my-2 space-y-4 px-2 pt-2 text-md sm:px-4">
      <div>
        North fans, complete the grid then add your details at the summary to go
        in the draw.
      </div>

      <div className="min-h-[176px] py-1 sm:py-2">
        <img src="/img/nmfc/240223-CB-Gridley_Takeover_Day_LaunchPromo.jpg" />
      </div>
      <div className="pl-0">
        <ul>
          <li>🦘 Delve into the North memory bank</li>
          <li>📱 Complete the grid</li>
          <li>📨 Submit for a chance to win</li>
        </ul>
      </div>
      <div>
        Entries close midnight AEDT,{" "}
        <span className="font-semibold">complete your grid now!</span>
      </div>
    </div>
  );
}

function HelpListItem({
  bgColor = "",
  textColor = "",
  highlightText = "",
  afterText,
}) {
  return (
    <li>
      {highlightText !== "" && (
        <span
          className={`${bgColor} ${textColor} -m-0.5 inline-block rounded-sm px-1 py-0.5 font-medium`}
        >
          {highlightText}
        </span>
      )}{" "}
      {afterText}
    </li>
  );
}

export default PromoDialog;
