import { BaseDialog } from "./BaseDialog";
import { UndoButton } from "../buttons/UndoButton";
import useGameStore from "../../lib/store/GameStore";
import usePersistentStore from "../../lib/store/PersistentStore";
import {
  getDateKeyForLevel,
  gridLevelDateKey,
  gridLevelNumber,
} from "../../lib/utils/gridLevels";
import { dateKeyToDate } from "../../lib/utils/dateFunctions";
import { formatDate } from "../../lib/utils/locales";
import { useState } from "react";

function GameHistoryDialog(props) {
  const [isOpen, setIsOpen] = useGameStore((state) => [
    state.isGameHistoryOpen,
    state.setIsGameHistoryOpen,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <BaseDialog
      isOpen={isOpen}
      closeModal={closeModal}
      size="md"
      modalTitle={
        <div className="">
          <div className="text-center text-xl font-semibold dark:text-white">
            Previous 7 Days
          </div>
        </div>
      }
      modalBody={
        <div className="pt-2">
          <GameHistoryList />
        </div>
      }
    />
  );
}

export function GameHistoryList({ showGrids = null }) {
  const [getLocalGridHistory] = usePersistentStore((state) => [
    state.getLocalGridHistory,
  ]);
  const [level] = useGameStore((state) => [state.levelNumber]);

  const [selectedLevel, setSelectedLevel] = useState(level);

  const showItems = showGrids || getLocalGridHistory();

  return (
    <div className="w-full space-y-1 text-center">
      {showItems.length == 0 && (
        <div className="my-1 px-4 py-4 text-left text-sm leading-snug sm:mx-0 sm:px-3 sm:py-2.5">
          <p className="mb-4">Well done – you've completed all Gridleys! 🔥</p>
          <p>Check back tomorrow for a new grid to play</p>
        </div>
      )}
      {showItems.map((item) => (
        <a
          key={item.level}
          href={`/${item.dateKey}`}
          onClick={() => setSelectedLevel(item.level)}
          className={`${
            item.level === selectedLevel ? "bg-slate-100" : "hover:bg-slate-100"
          } mx-2 flex select-none items-center justify-between rounded-xl px-3 py-3.5 text-sm font-medium leading-snug sm:mx-0 sm:px-3 sm:py-2.5
            `}
        >
          <div className="flex items-center justify-start">
            <img
              className="h-5 sm:h-6"
              src="img/gridley-ball.png"
              alt="Gridley Logo"
            />
            <span className="ml-2.5">#{item.level}</span>
            <span className="ml-2 text-xxs text-gray-700">•</span>
            <span className="ml-2">{formatDate(item.date, "MMM do")}</span>
          </div>
          <div className="mr-2 flex items-center justify-center">
            {/* {item.attempt && item.attempt.guessesLeft == 0 && (
              <div
                className={`flex items-center justify-center
                text-gray-900`}
              >
                {item.attempt.score}/9
              </div>
            )} */}
            <div
              className={`ml-2 flex w-7 items-center justify-center
                text-gray-900`}
            >
              {item.level === selectedLevel && (
                <IconCheckO className="h-5 w-5 text-slate-500" />
              )}
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}

function IconCheckO(props) {
  return (
    <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em" {...props}>
      <path
        fill="currentColor"
        d="M10.243 16.314L6 12.07l1.414-1.414 2.829 2.828 5.656-5.657 1.415 1.415-7.071 7.07z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm11 9a9 9 0 110-18 9 9 0 010 18z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export function GameHistoryTable() {
  const [localHistory, userHistory] = usePersistentStore((state) => [
    state.history,
    state.userHistory,
  ]);
  const [user] = useGameStore((state) => [state.user]);

  const handleRowClick = (item) => {
    // Construct the new URL based on the clicked row
    const newUrl = `/${item.dateKey}`;

    console.log(newUrl);
    // Navigate to the new URL
    window.location.href = newUrl;
  };

  const items = [];
  for (let level = gridLevelNumber; level >= 1; level--) {
    const dateKey = getDateKeyForLevel(level);
    const localAttempt = localHistory[dateKey];
    const userAttempt = userHistory?.[user?.id]?.[dateKey] ?? null;

    const attempt = userAttempt || localAttempt || null;

    items.push({ level: level, dateKey: dateKey, attempt });
  }

  return (
    <table className="mb-8 w-full text-center">
      {/* <thead className="border-primary border-b text-xxs uppercase text-gray-700 dark:text-gray-200 sm:text-sm">
        <tr>
          <th className="py-1 font-medium">Grid</th>
          <th className="py-1 font-medium">Date</th>
          <th className="py-1 font-medium">Score</th>
          <th className="py-1 font-medium">Rarity</th>
        </tr>
      </thead> */}
      <tbody>
        {items.map((item) =>
          item.attempt ? (
            <PlayedRow
              key={item.level}
              clickHandler={() => handleRowClick(item)}
              level={item.level}
              dateKey={item.dateKey}
              attempt={item.attempt}
            />
          ) : (
            <UnplayedRow
              key={item.level}
              clickHandler={() => handleRowClick(item)}
              level={item.level}
              dateKey={item.dateKey}
            />
          )
        )}
      </tbody>
    </table>
  );
}

function UnplayedRow({ level, dateKey, clickHandler }) {
  const bg = dateKey === gridLevelDateKey ? "bg-yellow-100" : "";

  return (
    <tr
      onClick={clickHandler}
      className={`cursor-pointer ${bg} border-b border-gray-100 text-xs hover:bg-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 sm:text-base`}
    >
      <td className="py-1.5 pl-4 text-blue-600 dark:text-blue-400">{level}</td>
      <td className="py-1.5">{dateKey}</td>
      <td className="py-1.5 text-blue-600 dark:text-blue-400"> Play Now! </td>
    </tr>
  );
}

function PlayedRow({ level, dateKey, clickHandler, attempt }) {
  const bg = dateKey === gridLevelDateKey ? "bg-yellow-100" : "";

  return (
    <tr
      onClick={clickHandler}
      className={`cursor-pointer ${bg} border-b border-gray-100 text-xs hover:bg-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 sm:text-base`}
    >
      <td className="py-1.5 pl-4 text-blue-600 dark:text-blue-400">{level}</td>
      <td className="py-1.5">{dateKey}</td>
      <td className="py-1.5">{attempt.score} / 9</td>
    </tr>
  );
}

export default GameHistoryDialog;
