import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { IconQuestionCircle } from "../icons/IconQuestionCircle";
import { iconClasses } from "@mui/material";

export default function ToolTip({ title, iconClassName, placement = "top" }) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        className="normal-case"
        PopperProps={{
          disablePortal: true,
        }}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: "0.875rem",
              lineHeight: "1.3",
              fontWeight: 400,
              fontFamily: "Inter",
              padding: "0.75rem 1rem",
              textAlign: "left",
              bgcolor: "rgba(0, 0, 0, 0.9)",
              "& .MuiTooltip-arrow": {
                color: "common.black",
              },
              textTransform: "none",
            },
          },
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        placement={placement}
        arrow
        title={title}
      >
        <button onClick={handleTooltipOpen}>
          <IconQuestionCircle className={iconClassName} />
        </button>
      </Tooltip>
    </ClickAwayListener>
  );
}
